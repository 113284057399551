import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-owp',
  templateUrl: './owp.component.html',
  styleUrls: ['./owp.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class OwpComponent implements OnInit {

  firstColumn: any[] = [];
  secondColumn: any[] = [];
  newPasswordForm: FormGroup;
  verifyPasswordForm: FormGroup;


  constructor(private fb: FormBuilder) {
    this.initNewPasswordForm();
    this.initVerifyPasswordForm();
  }

  ngOnInit() {
    this.showsOwp();
  }

  initNewPasswordForm() {
    this.newPasswordForm = this.fb.group({
      newPasswordInputBox1: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox2: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox3: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox4: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
    });
  }
  initVerifyPasswordForm() {
    this.verifyPasswordForm = this.fb.group({
      verifyPasswordInputBox1: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      verifyPasswordInputBox2: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      verifyPasswordInputBox3: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      verifyPasswordInputBox4: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
    });
  }

  inputValueChanged(event) {
 
  }

  get f() {
    return this.newPasswordForm.controls;
  }

  get v() {
    return this.verifyPasswordForm.controls;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showsOwp() {
    if (JSON.parse(localStorage.getItem("configurationData"))) {
      let configureData = JSON.parse(localStorage.getItem("configurationData"));
      let feature12Nc = [];
      feature12Nc = configureData.feature12Nc.map((item) => {
        return item.replace("-", "").toUpperCase();
      });
      const midpoint = Math.ceil(feature12Nc.length / 2);
      this.firstColumn = feature12Nc.slice(0, midpoint);
      this.secondColumn = feature12Nc.slice(midpoint);
    }
  };

  onSubmit() {

  }

}
